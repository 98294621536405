<template>
  <div class="w-full">
    <vx-card :class="$atividades.permissoes.includes(8) ? 'os overflow-hidden' : 'sem-autorizacao overflow-hidden'">
      <template slot="no-body">
        <div class="item-details px-2" @click="$atividades.permissoes.includes(8) ? (levantamentoModal = true, errors.clear()) : ''">
          <div class="my-1">
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
              <span>
                <vs-icon icon="phone_forwarded" color="success" class="img" size="30px" ></vs-icon>
              </span>
            </vs-col>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class>
              <span style="font-size: 20px" class="py-2">CONFIRMAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>

    <vs-popup :title="'CONFIRMAR ' + item.nomeCliente" :active.sync="levantamentoModal">
      <vs-row vs-type="flex" vs-align="center" id="concluir" class="vs-con-loading__container">
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-3">
          <div class="w-full p-0">
          <vs-input v-model="pessoaConfirmacao" class="p-0 m-0 inputx w-full" name='pessoaConfirmacao' label="Pessoa Confirmação" v-validate="'required'" />
          <span
              class="text-danger mb-2 text-sm"
              v-show="errors.has('pessoaConfirmacao')">
            {{$validators.empty}}</span>
          </div>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="center" vs-align="center" class="p-3">
          <vx-card class="osConf overflow-hidden" no-shadow @click="submitForm()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col vs-w="12" vs-type="flex" class="p-1" vs-justify="center" vs-align="center">
                    <span style="font-size: 20px">CONFIRMAR OS</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>

</template>
<script>
export default {
  props: {
    item: {
      required: true
    }
  },
  data() {
    return {
      levantamentoModal: false,
      pessoaConfirmacao: ''
    };
  },
  methods: {
    async Confirmar() {
      try {
        await this.$vs.loading({ container: "#concluir", scale: 0.6 });
        const res = await this.$http.put(`confirmarLevantamento/`+ this.item.id, {confirmado:1, pessoa_confirmacao: this.pessoaConfirmacao});
        await this.$vs.notify(this.$notify.Success);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Confirmação do levantamento N°" + res.id
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        await this.close()
        await this.$vs.loading.close("#concluir > .con-vs-loading");
      }
    },
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.Confirmar()
          } else {
            this.$vs.notify({
              title:'Preencha todos os campos',
              text:'Os campos não devem ser vazios!',
              color:'danger'
            })
          }
        })
      })
    },
    async close() {
      this.pessoaConfirmacao = ""
      this.levantamentoModal = await false;
    },
  }
};
</script>
<style lang="scss" scoped>

.os {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #00bba2;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
    background-color: #aaa;
    color: white;
    .img {
     color: white !important;
    }
}
</style>
