<template>
  <div>
    <div>
      <vs-row
        vs-type="flex"
        class="mb-4"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <vs-col vs-w="4" vs-justify="flex-start" vs-align="flex-start">
          <h4 style="color: #b4aa99">Confirmação de levantamentos.</h4>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="8"
        >
          <vs-input
            color="dark"
            class="mx-1"
            size="small"
            type="text"
            placeholder
            label="Busca"
            v-model="filtro.busca"
          ></vs-input>
          <vs-input
            size="small"
            class="mx-2"
            type="date"
            label="Data"
            v-model="filtro.data"
          ></vs-input>
          <div style="display: inline-block; margin-right: 20px">
            <label style="color: black; font-size: 13px">Turno</label><br />
            <el-select
              filterable
              clearable
              size="small"
              placeholder="Turno"
              v-model="filtro.turno"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="turno in turnos"
                :value="turno.id"
                :label="turno.nome"
                :key="turno.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            class="mx-2"
            size="small"
            plain
            :disabled="blockButton"
            type="primary"
            @click="Filtrar()"
            >Filtrar</el-button
          >
        </vs-col>
      </vs-row>
    </div>
    <vs-row vs-type="flex" vs-justify="flex-start">
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #000a12">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    NÃO CONFIRMADOS: {{ levantamentos.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <VuePerfectScrollbar
            class="scroll-lev-confirmacao"
            :settings="settings"
            style="background-color: #ebebeb"
          >
            <div id="levantamento_lista" class="vs-con-loading__container">
              <div v-if="!levantamentos.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>

              <div v-for="data in levantamentos" :key="data.id" class="mx-2">
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div class="w-full">
                          <vs-col vs-w="11">
                            <vs-col
                              class="pt-1"
                              vs-w="9"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <div
                              class="w-full"
                              @click="(iniciarModal = true), (selected = data)"
                            >
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-sm">
                                  Levantamento N°
                                  <b>
                                    {{ data.id }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-sm">
                                  Endereço:
                                  <b>
                                    {{ data.nomeBairro }},
                                    {{ data.logradouro }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                v-if="data.id_turno"
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              ></vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-sm">
                                  Data:
                                  <b>
                                    {{
                                      data.remarcado > 0
                                        ? data.data_remarcacao
                                        : data.data_competencia
                                          | moment("DD/MM/YYYY")
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <Badge
                              v-if="data.remarcado > 0"
                              text="REMARCADO"
                              color="danger"
                            ></Badge>
                            <Badge
                              v-if="data.nomeZona != null"
                              :text="data.nomeZona"
                              color="orange"
                            ></Badge>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </vs-col>
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #00bba2">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    CONFIRMADOS: {{ levantamentosConfirm.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <VuePerfectScrollbar
            class="scroll-lev-confirmacao"
            :settings="settings"
            style="background-color: #ebebeb"
          >
            <div id="levantamento_confirm" class="vs-con-loading__container">
              <div v-if="!levantamentosConfirm.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>

              <div
                v-for="data in levantamentosConfirm"
                :key="data.id"
                class="mx-2"
              >
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="11">
                            <vs-col
                              class="mt-1"
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              class="mt-1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                Levantamento N°
                                <b>
                                  {{ data.id }}
                                </b>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                Pessoa confirmação:
                                <b>
                                  {{ data.pessoa_confirmacao }}
                                </b>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                Endereço:
                                <b>
                                  {{ data.nomeBairro }},
                                  {{ data.logradouro }}
                                </b>
                              </span>
                            </vs-col>
                            <vs-col
                              v-if="data.id_turno"
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            ></vs-col>
                            <vs-col
                              v-if="data.data_competencia"
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                Data:
                                <b>
                                  {{
                                    data.remarcado > 0
                                      ? data.data_remarcacao
                                      : data.data_competencia
                                        | moment("DD/MM/YYYY")
                                  }}
                                </b>
                              </span>
                            </vs-col>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <Badge
                              v-if="data.nomeZona != null"
                              :text="data.nomeZona"
                              color="orange"
                            ></Badge>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </vs-col>
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #f44336">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    REMARCADOS: {{ levantamentosRemarc.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <VuePerfectScrollbar
            class="scroll-lev-confirmacao"
            :settings="settings"
            style="background-color: #ebebeb"
          >
            <div id="levantamento_remarc" class="vs-con-loading__container">
              <div v-if="!levantamentosRemarc.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>

              <div
                v-for="data in levantamentosRemarc"
                :key="data.id"
                class="mx-2"
              >
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div class="w-full">
                          <vs-col vs-w="11">
                            <vs-col
                              class="mt-1"
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <div
                              class="w-full"
                              @click="(iniciarModal = true), (selected = data)"
                            >
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-sm">
                                  Levantamento N°
                                  <b>
                                    {{ data.id }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-sm">
                                  Endereço:
                                  <b>
                                    {{ data.nomeBairro }},
                                    {{ data.logradouro }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-sm">
                                  Data:
                                  <b>
                                    {{
                                      data.remarcado > 0
                                        ? data.data_remarcacao
                                        : data.data_competencia
                                          | moment("DD/MM/YYYY")
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <Badge
                              v-if="data.nomeZona != null"
                              :text="data.nomeZona"
                              color="orange"
                            ></Badge>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </vs-col>
    </vs-row>
    <vs-popup
      class
      :title="'' + selected.nomeCliente"
      :active.sync="iniciarModal"
    >
      <vs-row
        vs-type="flex"
        vs-align="center"
        class="vs-con-loading__container"
        id="loading"
      >
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <modalConfirmarLevantamento
            @update="close()"
            :item="selected"
          ></modalConfirmarLevantamento>
        </vs-col>
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <modalReagendarLevantamento
            @update="close()"
            :item="selected"
          ></modalReagendarLevantamento>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import modalConfirmarLevantamento from "./components/modalConfirmarLevantamento.vue"
import modalReagendarLevantamento from "./components/modalReagendarLevantamento.vue"
import VisualizarCliente from "./../cliente/visualizar.vue"

export default {
  data () {
    return {
      filtro: {
        dataHoje: this.$formartData.dataFiltro()
      },
      selected: [],
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" }
      ],
      cardClass: "prospeccao-card overflow-hidden",

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      iniciarModal: false,
      atividades: [10, 18],
      levantamentos: [],
      levantamentosConfirm: [],
      levantamentosRemarc: [],
      reRender: 1,
      blockButton: false
    }
  },
  async mounted () {
    this.$vs.loading()
    await this.getLevantamentos()
    await this.getLevantamentosConfirm()
    await this.getLevantamentosRemarc()
    this.$vs.loading.close()
  },
  methods: {
    async Filtrar () {
      this.blockButton = true
      await this.getLevantamentos()
      await this.getLevantamentosConfirm()
      await this.getLevantamentosRemarc()
      this.blockButton = false
    },
    async getLevantamentos () {
      await this.$vs.loading({ container: "#levantamento_lista", scale: 0.6 })
      try {
        this.levantamentos = await this.$http.post(
          `getLevantamentos`,
          this.filtro
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#levantamento_lista > .con-vs-loading")
      }
    },
    async getLevantamentosConfirm () {
      await this.$vs.loading({
        container: "#levantamento_confirm",
        scale: 0.6
      })
      try {
        this.levantamentosConfirm = await this.$http.post(
          `getLevantamentosConf`,
          this.filtro
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#levantamento_confirm > .con-vs-loading")
      }
    },
    async getLevantamentosRemarc () {
      await this.$vs.loading({ container: "#levantamento_remarc", scale: 0.6 })
      try {
        this.levantamentosRemarc = await this.$http.post(
          `getLevantamentosRemarc`,
          this.filtro
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#levantamento_remarc > .con-vs-loading")
      }
    },
    async close () {
      this.iniciarModal = await false
      await this.getLevantamentos()
      await this.getLevantamentosConfirm()
      await this.getLevantamentosRemarc()
    }
  },
  components: {
    ...components,
    modalConfirmarLevantamento,
    modalReagendarLevantamento,
    VisualizarCliente
  }
};
</script>
<style scoped lang="scss">
.list-item {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
}
.list-item:hover {
  background: #dcdcdc;
}
.active-list-item {
  background-color: #000a12;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
  color: #ffca00;
}
.prospeccao-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-lev-confirmacao {
  height: 70vh !important;
}
</style>
