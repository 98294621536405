<template>
  <div class="w-full">
    <vx-card
      :class="
        $atividades.permissoes.includes(2)
          ? 'reagendar overflow-hidden'
          : 'sem-autorizacao overflow-hidden'
      "
      @click="$atividades.permissoes.includes(2) ? (reagendarModal = true) : ''"
    >
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="p-5"
            >
              <span>
                <vs-icon
                  icon="phone_missed"
                  size="30px"
                  color="#f44336"
                  class="img"
                ></vs-icon>
              </span>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class
            >
              <span style="font-size: 20px" class="py-2">REMARCAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>

    <vs-popup
      :title="'REMARCAR: ' + item.nomeCliente"
      :active.sync="reagendarModal"
    >
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-3"
        >
          <vs-textarea v-model="obsReagenda" label="Reporte o que aconteceu" />
        </vs-col>
        <vs-col
          vs-w="5"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class
        >
          <vs-input
            v-model="dataReagenda"
            label="Data"
            type="date"
            name="Data"
            class="inputx w-full p-3"
          />
        </vs-col>
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-2"
        >
          <vx-card class="reagendarConf overflow-hidden" @click="Reagendar()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="p-1"
                  >
                    <span style="font-size: 20px">CONFIRMAR REMARCAÇÃO</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true
    }
  },
  data () {
    return {
      reagendarModal: false,
      obsReagenda: "",
      dataReagenda: "",
    }
  },
  methods: {
    async Reagendar () {
      if (
        this.dataReagenda == undefined ||
        this.dataReagenda == null ||
        this.dataReagenda == ""
      ) {
        const error = this.$httpErrors("erro")
        this.$vs.notify(error)
      } else {
        let obs = this.obsReagenda
        let id = this.item.id
        let dataReagenda = this.dataReagenda
        try {
          const res = await this.$http.put(`reagendarLevantamento`, {
            id,
            obs,
            dataReagenda,
            confirmado: 0
          })
          this.close()
          await this.$vs.notify(this.$notify.Success)
          // await this.logAssunto(res)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        } finally {
          await this.$emit("update")
        }
      }
    },
    async close () {
      this.obsReagenda = ""
      this.dataReagenda = ""
      this.reagendarModal = await false
    },
    async logAssunto (res) {
      try {
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'atualizar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Reagendamento do levantamento N°' + res.id
        }
        await this.$logger(logData)
        // await this.$http.post("assuntoCliente", {
        //   id_assuntos: 5,
        //   id_cliente: res.id,
        //   id_estagio: 1,
        //   status: 2,
        //   tipo: 1,
        //   id_colaborador: window.localStorage.getItem("id"),
        //   obs: this.obsReagenda
        // });
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.grid-view-item {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.reagendar {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.reagendarConf {
  background-color: #f44336;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
